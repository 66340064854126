// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// AdminLTE
@import "~admin-lte/plugins/fontawesome-free/css/all.min.css";
@import "~admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css";
@import "~admin-lte/plugins/select2/css/select2.min.css";
@import "~admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css";
@import "~admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css";
@import "~admin-lte/dist/css/adminlte.min.css";

//custom style per page
@import 'auth';
